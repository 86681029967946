import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/webprojectcard.css"
import Particle12 from "../components/Particle12"
import SEO from "../components/SEO"
import { Link } from "gatsby"

const tree = () => {
  return (
    <div>
      <Layout>
        <SEO
          title="Tree Programs"
          description="Programs of Tree under Data Structures and Algorithm with Python."
        />
        <Particle12></Particle12>
        <h6>s</h6>
        <section className="noSelect">
          <Link to="/dsa/">
            <button type="button" className="back-btn noSelect">
              <span>{"<"}= More Programs</span>
            </button>
          </Link>
        </section>
        <div className="programming-project">
          <h1>Tree</h1>
        </div>

        <section className="most-liked noSelect">
          <h3 className="total">Total Programs :- 10</h3>
          <div className="row">
            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Representation of Binary tree using array
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a node, with left and right part
                  <br />
                  •Take input in array
                  <br />
                  •Perform Inorder
                  <br />
                  •Print the Tree
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Representation%20of%20Binary%20tree%20using%20array.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Binary Tree Traversal</h3>
                </div>
                <p className="basic-op">
                  •Create a node, with left and right
                  <br />
                  •Traverse Preorder
                  <br />
                  •Traverse Inorder
                  <br />
                  •Traverse Postorder
                  <br />
                  •Print all traversals
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Binary%20Tree%20Traversal.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Finding the number of nodes in a binary tree
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a Binary Tree
                  <br />
                  •Count full nodes with help of root
                  <br />
                  •Print it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Finding%20the%20number%20of%20nodes%20in%20a%20binary%20tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Finding the height of a binary tree
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a Binary Tree
                  <br />
                  •Stay connected with root
                  <br />
                  •Move with node count
                  <br />
                  •Print height of Tree
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Finding%20the%20height%20of%20a%20binary%20tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">
                    Finding the number of leaves in a binary tree
                  </h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Count with help of left and right
                  <br />
                  •Print it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Finding%20the%20number%20of%20leaves%20in%20a%20binary%20tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Deleting a binary tree</h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Delete left and right of node
                  <br />
                  •Must clear the node reference
                  <br />
                  •Tree is deleted
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Deleting%20a%20binary%20tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Full Binary Tree</h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Check if every node has 0 or 2 children
                  <br />
                  •Print Tree is Full or not
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Full%20Binary%20Tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Complete Binary Tree</h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Check all levels are completely filled and last level has all
                  keys as left as possible
                  <br />
                  •Print 'is Tree Complete?'
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Complete%20Binary%20Tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Perfect Binary Tree</h3>
                </div>
                <p className="basic-op">
                  •Create a node
                  <br />
                  •Check all the internal nodes have two children and all leaf
                  nodes are at the same level
                  <br />
                  •Print Tree is Perfect or not
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Perfect%20Binary%20Tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="rotate">
              <div className="coffee-card">
                <div className="card-header">
                  <h3 className="title-text">Binary Search Tree</h3>
                </div>
                <p className="basic-op">
                  •Remember the properties
                  <br />
                  •Create a node
                  <br />
                  •Insert the key
                  <br />
                  •Delete key
                  <br />
                  •Traverse Inorder and print it
                </p>
                <div className="back">
                  <div className="back-content">
                    <div className="layer"></div>
                    <h4 className="text">Checkout here...</h4>
                    <div className="bttn-box">
                      <a
                        href="https://github.com/arpitsomani8/Data-Structures-And-Algorithm-With-Python/blob/master/Tree/Binary%20Search%20Tree.py"
                        className="btntt"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Github Code
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export default tree
